import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../store";
import { useAppSelector } from "../hooks";
import { PrivateLayout } from "../container";

export const AdminRoutes = () => {
    const isAuthenticated = useAppSelector((state: RootState) => state?.auth?.isAuthenticated);
    const isAdmin = useAppSelector((state: RootState) => state?.auth?.role);

    if (isAuthenticated && isAdmin === "admin") {
        return (
            <PrivateLayout>
            <Outlet />
        </PrivateLayout>
        );
    }
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }
    return <Navigate to="/" />;
};