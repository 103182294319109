// AppRouter.jsx
import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./RoutesConfig";  // Import the new route configuration

const AppRouter: React.FC = () => {
  return (
    <RouterProvider router={router} />
  );
};

export default AppRouter;