import { useEffect, useState } from "react";
import { ClearVerification } from "../components/Auth/ClearVerification";
import { Steps } from "antd";
import { RegisterForm } from "../components/Auth/RegisterForm";
import { useSearchParams } from "react-router-dom";
declare global {
  interface Window {
    ClearMe: any; // Consider defining a more precise type based on ClearMe's documentation
  }
}

export const Signup = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [userToken, setUserToken] = useState("");
  const [authCode] = useState("");
  const [error] = useState(null);
  const [searchParams] = useSearchParams();
  const [endPoint, setendPoint] = useState("");
  const clientId = process.env.REACT_APP_CLEAR_CLIENT_ID;
  // const scope = process.env.REACT_APP_SCOPE;
  const scope =
    "given_name+middle_name+family_name+birthdate+email+gender+urn:clearme:biographics:address:postal_code+urn:clearme:biographics:address:country";
  const host_endpoint = process.env.REACT_APP_HOST_ENDPOINT;
  const callbackUrl = process.env.REACT_APP_CLEAR_CALLBACK_URL;

  useEffect(() => {
    try {
      if (!searchParams.get("state") && searchParams.get("code")) {
        setUserToken(searchParams.get("code"));
        // localStorage.setItem("userId", searchParams.get("code"));
        console.log("searchParams.get(code)", searchParams.get("code"));
        setCurrentStep(1);
        setendPoint(
          `${host_endpoint}/?clientId=${encodeURIComponent(clientId)}&state=${encodeURIComponent(
            userToken
          )}&scope=${scope}&redirectURI=${encodeURIComponent(callbackUrl)}`
        );
      }
    } catch (error) {
      console.error("Error in useEffect", error);
    }
  }, [searchParams, userToken, authCode]);

  return (
    <div className="min-h-screen bg-slate-200">
      <div className="py-12 grid place-items-center">
        {/* grid grid-cols-12 p-50 */}
        <div className="px-6 md:w-[550px] sm:w-ful w-full">
          {/* col-span-12 md:col-start-5 md:col-span-4 mt-10 items-center justify-center */}
          <div className="mb-5">
            <Steps
              current={currentStep}
              items={[
                {
                  title: "Registration",
                },
                {
                  title: "Verification",
                },
              ]}
            />
            {error && <div className="mb-4 text-red-500">An error occurred: {error}</div>}
            {currentStep === 1 && <ClearVerification endpoint={endPoint} userToken={userToken} />}
            {currentStep === 0 && <RegisterForm />}
          </div>
        </div>
      </div>
    </div>
  );
};
