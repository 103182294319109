import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, TelegramShareButton, RedditShareButton, PinterestShareButton, EmailShareButton, FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, TelegramIcon, RedditIcon, PinterestIcon, EmailIcon  } from 'react-share';

export const ShareButtons = ({ url, title }) => {
  const baseUrl = window.location.origin;
  const defaultMedia = baseUrl + "/img/logo1.png";
  return (
  <div className="flex gap-6">
    <FacebookShareButton url={url}>
      <FacebookIcon size={32} round />
    </FacebookShareButton>
    <TwitterShareButton url={url} title={title}>
      <TwitterIcon size={32} round />
    </TwitterShareButton>
    <WhatsappShareButton url={url}>
      <WhatsappIcon size={32} round/>
    </WhatsappShareButton>
    <TelegramShareButton url={url} >
      <TelegramIcon size={32} round/>
    </TelegramShareButton>
    <RedditShareButton url={url} >
      <RedditIcon size={32} round/>
    </RedditShareButton>
    <LinkedinShareButton url={url} title={title}>
      <LinkedinIcon size={32} round />
    </LinkedinShareButton>
    <PinterestShareButton url={url} media={defaultMedia}>
      <PinterestIcon size={32} round />
    </PinterestShareButton>
    <EmailShareButton url={url} subject={title}>
      <EmailIcon size={32} round/>
    </EmailShareButton>
  </div>
  );
};