import { AddOption, Button, DescriptionBox, Option, PrivateContainer, QuestionBox, TagBox } from "../components";
import { useState } from "react";
import { Switch, Select, Input, InputNumber, Collapse, Tooltip } from "antd";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CREATE_POLL, FETCH_QUESTIONS } from "../graphql/models";
import { successNotify, warnNotify } from "../util";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import { reloadMyPolls } from "../store/slices/auth.slice";

const { Panel } = Collapse;

interface ICreatePoll {
  setOpen?: Function;
  notFullHeight?: boolean;
}

interface ValidationErrors {
  question?: string;
  description?: string;
  category?: string;
  options?: string;
  suggestionable?: boolean;
}

export const CreatePolls = ({ setOpen, notFullHeight }: ICreatePoll) => {
  const [question, setQuestion] = useState("");
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [suggestionable, setIsSuggestion] = useState(false);
  const [category, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [locationState, setLocationState] = useState(null);
  const [gender, setGender] = useState(null);
  const [minAge, setMinAge] = useState<number | null>(null);
  const [maxAge, setMaxAge] = useState<number | null>(null);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [touchedFields, setTouchedFields] = useState<{ [key: string]: boolean }>({});
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onDeleteOption = (idx: number) => {
    const filterData = options.filter((opt, index) => index !== idx);
    setOptions([...filterData]);
  };

  const { data: questions } = useQuery(FETCH_QUESTIONS, {
    fetchPolicy: "cache-and-network",
  });

  const validateForm = () => {
    const errors: ValidationErrors = {};
    if (!question) errors.question = "Question is required";
    if (!category) errors.category = "Category is required";
    if (!suggestionable && options.length === 0) errors.options = "At least one option is required unless suggestions are enabled";

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  const resetForm = () => {
    setQuestion("");
    setDescription("");
    setOptions([]);
    setTags([]);
    setIsSuggestion(false);
    setCategory("");
    setCity("");
    setLocationState("");
    setGender("");
    setMinAge(null);
    setMaxAge(null);
  };

  const [CreatePollMutation, { loading }] = useMutation(CREATE_POLL);
  const onCreatePoll = async () => {
    try {
      const {
        data: { CreatePoll },
      } = await CreatePollMutation({
        variables: {
          pollInput: {
            question,
            description,
            suggestionable,
            category,
            locationRestriction: { city, locationState },
            genderRestriction: { gender },
            ageRangeRestriction: { minAge, maxAge },
          },
          tags,
          options,
        },
      });
      if (CreatePoll.success) {
        successNotify(CreatePoll.message);
        if (typeof setOpen === "function") {
          setOpen(false);
          dispatch(reloadMyPolls({ reload: Date.now() }));
        }
        resetForm();
        navigate("/my-polls");
      } else {
        warnNotify(CreatePoll.message);
      }
    } catch (error) {
      console.error(error);
      warnNotify("Failed to create poll.  Please try again.");
    }
  };

  const handleBlur = (field: string) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
    validateForm();
  };

  const disable =
    !question ||
    !category ||
    (options.length === 0 && !suggestionable) ||
    Object.keys(validationErrors).length > 0;

  return (
    <PrivateContainer pageTitle="Create Poll" notFullHeight={notFullHeight}>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <QuestionBox
            ques={question}
            setQues={setQuestion}
            oldPolls={questions?.FetchPoll.result}
            onBlur={() => handleBlur("question")}
            validationError={touchedFields.question ? validationErrors.question || "" : ""}
          />
          <DescriptionBox
            description={description}
            setDescription={setDescription}
            onBlur={() => handleBlur("description")}
          />
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-12 sm:col-span-6">
          <label className="text-xl font-semibold mb-5">
            Category <span className="text-red-500">*</span>
            {touchedFields.category && validationErrors.category && (
              <span className="text-red-500 text-sm font-normal ml-3">{validationErrors.category}</span>
            )}
          </label>

          <Select
            className="mt-1 block w-2/3 py-2 font-bold border-gray-300 focus:border-2 focus:border-gray-500 sm:text-sm rounded-lg"
            showSearch
            placeholder="Select a category"
            optionFilterProp="children"
            onChange={(value) => setCategory(value)}
            value={category}
            onBlur={() => handleBlur("category")}
          >
            <Select.Option key="food" value="food">Food</Select.Option>
            <Select.Option key="sports" value="sports">Sports</Select.Option>
            <Select.Option key="travel" value="travel">Travel</Select.Option>
            <Select.Option key="media" value="media">Media</Select.Option>
            <Select.Option key="technology" value="technology">Technology</Select.Option>
            <Select.Option key="health" value="health">Health</Select.Option>
            <Select.Option key="economics" value="economics">Economics</Select.Option>
            <Select.Option key="social" value="social">Social Issues</Select.Option>
            <Select.Option key="politics" value="politics">Politics</Select.Option>
          </Select>
        </div>
        <div className="col-span-12 sm:col-span-6">
          <TagBox tags={tags} setTags={setTags} />
        </div>
      </div>
      <div className="text-xl font-semibold mb-5 flex flex-col">
        <div>
          <span>Answers</span>
          <span className="text-sm ms-2 text-gray-400">(up to 10)</span>
          <span className="text-red-500">*</span>
        </div>{" "}
        {touchedFields.options && validationErrors.options && (
          <span className="text-red-500 text-sm font-normal">{validationErrors.options}</span>
        )}
      </div>

      {options.map((opt, idx) => (
        <Option key={idx} data={opt} idx={idx} onDeleteOption={onDeleteOption} />
      ))}
      {options?.length < 10 && (
        <div className="grid grid-cols-12 mb-10">
          <div className="col-span-12 sm:col-span-6 md:col-span-4">
            <AddOption options={options} setOptions={setOptions} onBlur={() => handleBlur("options")} />
          </div>
        </div>
      )}
      <div className="grid grid-cols-12 mb-10">
        <div className="col-span-12 ">
          <div className="text-xl font-semibold mb-2">Allow Answer Suggestions</div>
          <Switch
            className="bg-gray-400"
            checked={suggestionable}
            onChange={(check) => setIsSuggestion(check)}
            checkedChildren="ON"
            unCheckedChildren="OFF"
          />
        </div>
      </div>

      <Collapse className="mb-10">
        <Panel header="Optional: Restrict Who Can Vote" key="1">
          <Tooltip title="These restrictions are optional and will limit which users can vote on this poll.">
            <p className="text-gray-500 mb-4">You can restrict who can vote based on city, state, gender, and age.</p>
          </Tooltip>

          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 sm:col-span-6">
              <label className="text-xl font-semibold mb-2">City</label>
              <Input placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
            </div>
            <div className="col-span-12 sm:col-span-6">
              <label className="text-xl font-semibold mb-2">State</label>
              <Select
                className="block w-full font-bold border-gray-300 focus:border-2 focus:border-gray-500 sm:text-sm rounded-lg"
                showSearch
                placeholder="Select a state"
                value={locationState}
                onChange={(value) => setLocationState(value)}
              >
                {[
                  "AL",
                  "AK",
                  "AZ",
                  "AR",
                  "CA",
                  "CO",
                  "CT",
                  "DE",
                  "FL",
                  "GA",
                  "HI",
                  "ID",
                  "IL",
                  "IN",
                  "IA",
                  "KS",
                  "KY",
                  "LA",
                  "ME",
                  "MD",
                  "MA",
                  "MI",
                  "MN",
                  "MS",
                  "MO",
                  "MT",
                  "NE",
                  "NV",
                  "NH",
                  "NJ",
                  "NM",
                  "NY",
                  "NC",
                  "ND",
                  "OH",
                  "OK",
                  "OR",
                  "PA",
                  "RI",
                  "SC",
                  "SD",
                  "TN",
                  "TX",
                  "UT",
                  "VT",
                  "VA",
                  "WA",
                  "WV",
                  "WI",
                  "WY",
                ].map((stateCode) => (
                  <Select.Option key={stateCode} value={stateCode}>
                    {stateCode}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-4 mt-4">
            <div className="col-span-12 sm:col-span-6">
              <label className="text-xl font-semibold mb-2">Gender Restriction</label>
              <Select
                className="block w-full font-bold border-gray-300 focus:border-2 focus:border-gray-500 sm:text-sm rounded-lg"
                showSearch
                placeholder="Select gender restriction"
                onChange={(value) => setGender(value)}
                value={gender}
              >
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
              </Select>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-4 mt-4">
            <div className="col-span-12 sm:col-span-6">
              <label className="text-xl font-semibold mb-2">Min Age</label>
              <InputNumber
                placeholder="Min Age"
                value={minAge}
                onChange={(value) => setMinAge(value)}
                className="w-full"
              />
            </div>
            <div className="col-span-12 sm:col-span-6">
              <label className="text-xl font-semibold mb-2">Max Age</label>
              <InputNumber
                placeholder="Max Age"
                value={maxAge}
                onChange={(value) => setMaxAge(value)}
                className="w-full"
              />
            </div>
          </div>
        </Panel>
      </Collapse>

      <div className="grid grid-cols-12">
        <div className="col-span-12 sm:col-span-4 md:col-span-3 col-start-1 sm:col-start-9 md:col-start-10">
          <Button
            text="Create"
            disabled={disable}
            loading={loading}
            onClick={onCreatePoll}
            className="bg-blue-600 w-full hover:bg-blue-700 font-bold text-lg py-2"
          />
        </div>
      </div>
    </PrivateContainer>
  );
};