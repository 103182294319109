import { FC, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FETCH_USER_NOTIFICATIONS_NEW, UPDATE_NOTIFICATION } from "../graphql/models";
import { Dropdown, Menu, Badge } from "antd";
import { BellOutlined } from "@ant-design/icons";

interface Notification {
  _id: string;
  title: string;
  message: string;
  userId: string;
  notify_time: string;
  isNewNotification: boolean;
  pollId: string;
}

interface NotificationResponse {
  code: string;
  count: number;
  notifications: Notification[];
}

interface FetchNewNotificationsData {
  fetchNewNotifications: NotificationResponse;
}

interface NotificationsProps {
  userId: string;
}

const Notifications: FC<NotificationsProps> = ({ userId }) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const navigate = useNavigate();
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);
  const { data, error, refetch } = useQuery<FetchNewNotificationsData>(FETCH_USER_NOTIFICATIONS_NEW, {
    variables: { userId },
    fetchPolicy: "network-only", // Ensures fresh data
  });
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    if (data) {
      setNotificationCount(data.fetchNewNotifications.count);
    }
  }, [data]);

  const handleNotificationClick = useCallback(async () => {
    // Assuming marking all notifications as read
    const notificationUpdates = data?.fetchNewNotifications.notifications.map((notification) =>
      updateNotification({
        variables: {
          notifyId: notification._id,
          isNewNotification: false,
        },
      })
    );

    await Promise.all(notificationUpdates);
    refetch();
  }, [data?.fetchNewNotifications.notifications, updateNotification, refetch]);

  const formatDate = (dateStr: string) => {
    const date = new Date(parseInt(dateStr));
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) return "Today";
    if (date.toDateString() === yesterday.toDateString()) return "Yesterday";
    return date.toLocaleDateString("default", { month: "long", day: "numeric", year: "numeric" });
  };

  const handleDropdownChange = (open: boolean) => {
    setShowNotifications(open);
    if (open && data?.fetchNewNotifications.count > 0) {
      handleNotificationClick(); // Mark notifications as read when the dropdown is opened
    }
  };

  if (error) return <p>An error occurred</p>;

  const menu = (
    <Menu>
      {data?.fetchNewNotifications.count > 0 ? (
        <>
          {data.fetchNewNotifications.notifications.map((notification) => (
            <Menu.Item
              key={notification._id}
              onClick={() => {
                if (notification.pollId) {
                  navigate(`/polls/${notification.pollId}`);
                } else {
                  navigate("/notifications");
                }
              }}
            >
              <div className="px-4 py-2">
                <strong className="block text-sm font-semibold text-gray-800">{notification.title}</strong>
                <div className="text-xs text-gray-500">{formatDate(notification.notify_time)}</div>
              </div>
            </Menu.Item>
          ))}
          {/* <Menu.Item key="clear_all" className="text-center" onClick={handleNotificationClick}>
            <button className="text-red-500 text-xs font-semibold hover:text-red-600 transition-colors">
              Clear All
            </button>
          </Menu.Item> */}
        </>
      ) : (
        <Menu.Item key="1" onClick={() => navigate("/notifications")}>
          No New Notifications
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} onOpenChange={handleDropdownChange} open={showNotifications}>
      <Badge className="text-xs" count={notificationCount} offset={[-2, 10]}>
        <BellOutlined className="text-2xl text-white" />
      </Badge>
    </Dropdown>
  );
};

export default Notifications;
