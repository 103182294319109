import { createBrowserRouter, Outlet } from "react-router-dom";
import ErrorBoundary  from "../container/ErrorBoundary";
import { PrivateRoutes } from "./PrivateRoutes";
import { AdminRoutes } from "./AdminRoutes";
import { AuthRoutes } from "./AuthRoutes";
import {
  AboutUs,
  AccountNotifications,
  AdminPage,
  Signup,
  CreatePolls,
  Disclaimer,
  FollowedPolls,
  ForgotEmail,
  ForgotPassword,
  Home,
  MyPolls,
  PollDetails,
  PrivacyPolicy,
  ResetPassword,
  // Root,
  Settings,
  Signin,
  TermsAndCondition,
  VotedPolls,
  // VerifyEmail,
} from "../pages";
import { VerifyEmail } from "../pages/VerifyEmail";
import { Root } from "../pages/Root";

const router = createBrowserRouter([
  {
  path: '/',
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
  {
    index: true,
    element: <Home />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/categories/:category",
    element: <Home />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/poll/:category/:code/*",
    element: <PollDetails />,
    errorElement: <ErrorBoundary />,
  },
  {
    element: <AuthRoutes />,
    children: [
      {
        path: "/login",
        element: <Signin />,
      },
      {
        path: "/register",
        element: <Signup />,
      },
      {
        path: "/forgot-email",
        element: <ForgotEmail />,
      },
      {
        path: "/forget-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: '/notifications',
        element: <AccountNotifications />,
      },
      {
        path: '/my-polls',
        element: <MyPolls />,
      },
      {
        path: '/voted-polls',
        element: <VotedPolls />,
      },
      {
        path: '/followed-polls',
        element: <FollowedPolls />,
      },
      {
        path: '/create-poll',
        element: <CreatePolls />,
      },
      {
        path: '/settings',
        element: <Settings />,
      },
    ],
  },
  {
    element: <AdminRoutes />,
    children: [
      {
        path: "/admin",
        element: <AdminPage />,
      },
    ],
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/disclaimer",
    element: <Disclaimer />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndCondition />,
  },
  {
    path: "/verify-email",
    element: <VerifyEmail />,
  },
]
}
]);

export default router;
