import { useMutation } from "@apollo/react-hooks";
import { Drawer, Popconfirm, Spin, Tag, Tooltip, Popover } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks";
import { setSearchText, setPage } from "../../store/slices/auth.slice";
import { useNavigate } from "react-router-dom";
import { ADD_OR_REMOVE_POLL, ADD_VOTE, REMOVE_POLL, FETCH_COMMENT } from "../../graphql/models";
import { successNotify, warnNotify } from "../../util";
import { AddSuggession } from "../CreatePoll/AddSuggession";
import { PollCardDrawer } from "./PollCardDrawer";
import { PollOption } from "./PollOptions";
import { RestrictionTag } from "./RestrictionTag";
import { sanitizeQuestion } from "../../util";
import { ShareButtons } from "../Home/ShareButtons";

interface pollCardInter {
  data?: any;
  totalVote?: any;
  refetch?: Function;
  setPage?: Function;
  localPage?: number;
}

export const PollCard = ({ data, totalVote, refetch, localPage }: pollCardInter) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const { user, page } = useSelector((state: any) => state?.auth);
  const dispatch = useAppDispatch();
  const { tags, options } = data || {};
  const isMine = user?._id !== undefined ? user?._id === data?.user?._id : false;
  const isPollVoted = options.find(({ votes }) => votes.find((item) => item.user?._id === user?._id))?._id;
  const [addVoteMutation, { loading }] = useMutation(ADD_VOTE);

  const onAddVote = async (optionId: string) => {
    try {
      if (optionId === isPollVoted) return;
      else if (!user?._id) {
        navigate("/login");
        return;
      } else if (data?.user?._id === user._id) return;
      if (data?.locationRestriction) {
        const normalizeValue = (value) => (value ? value.trim() : null);
        const restrictedCity = normalizeValue(data.locationRestriction.city);
        const restrictedState = normalizeValue(data.locationRestriction.locationState);

        if (restrictedCity && restrictedState) {
          if (user.city !== restrictedCity || user.locationState !== restrictedState) {
            warnNotify(`This poll is only for residents of ${restrictedCity}, ${restrictedState}`);
            return;
          }
        } else if (!restrictedCity && restrictedState) {
          if (user.locationState !== restrictedState) {
            warnNotify(`This poll is only for residents of the state of ${restrictedState}`);
            return;
          }
        } else if (restrictedCity && !restrictedState) {
          if (user.city !== restrictedCity) {
            warnNotify(`This poll is only for residents of ${restrictedCity}`);
            return;
          }
        }
      }
      if (data?.genderRestriction && data?.genderRestriction.gender) {
        if (user?.gender !== data.genderRestriction?.gender) {
          warnNotify(`This poll is only for ${data?.genderRestriction?.gender}s`);
          return;
        }
      }
      if (data?.ageRangeRestriction) {
        if (data.ageRangeRestriction.minAge != null && data.ageRangeRestriction.maxAge != null) {
          if (user?.age < data.ageRangeRestriction?.minAge || user?.age > data.ageRangeRestriction?.maxAge) {
            warnNotify(
              `This poll is only for people between the ages of ${data?.ageRangeRestriction?.minAge} and ${data?.ageRangeRestriction?.maxAge}`
            );
            return;
          }
        }
      }
      const {
        data: { AddVote },
      } = await addVoteMutation({
        variables: {
          optionId,
          pollId: data._id,
        },
      });
      if (AddVote.success) {
        // successNotify(AddVote.message);
        refetch();
      } else {
        warnNotify(AddVote.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [removePollMutation] = useMutation(REMOVE_POLL);
  const onRemovePoll = async (e) => {
    e.stopPropagation();
    try {
      const {
        data: { RemovePoll },
      } = await removePollMutation({
        variables: {
          pollId: data._id,
        },
      });
      if (RemovePoll.success) {
        successNotify(RemovePoll.message);
        refetch();
      } else {
        warnNotify(RemovePoll.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const [addOrRemoveFollowerMutation] = useMutation(ADD_OR_REMOVE_POLL);
  const onAddOrRemoveFollower = async (e) => {
    e.stopPropagation();
    try {
      const {
        data: { AddOrRemoveFollower },
      } = await addOrRemoveFollowerMutation({
        variables: {
          pollId: data._id,
        },
      });
      if (AddOrRemoveFollower.success) {
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const filterByTag = (value) => {
    dispatch(setSearchText({ searchTag: value }));
    dispatch(setPage(1));
  };

  const generateQuestionIdAndRestriction = (question, locationRestriction, genderRestriction, ageRangeRestriction) => {
    const hasWhitespaceInMiddle = (str) => {
      return /\S\s+\S/.test(str);
    };

    const updatedLocationRestriction = { ...locationRestriction };
    if (hasWhitespaceInMiddle(updatedLocationRestriction?.city)) {
      const replaceWhitespaceWithDash = (str) => {
        return str.replace(/\s+/g, "-");
      };
      const city = updatedLocationRestriction?.city || "";
      const updatedCity = replaceWhitespaceWithDash(city);
      updatedLocationRestriction.city = updatedCity;
    }
    const cityState =
      updatedLocationRestriction?.city && updatedLocationRestriction?.locationState
        ? `${updatedLocationRestriction.city}-${updatedLocationRestriction.locationState}`
        : updatedLocationRestriction?.city
        ? updatedLocationRestriction.city
        : updatedLocationRestriction?.locationState
        ? updatedLocationRestriction.locationState
        : "";
    const gender = genderRestriction.gender || "";
    const ageRange =
      ageRangeRestriction.minAge && ageRangeRestriction.maxAge
        ? `${ageRangeRestriction.minAge}-${ageRangeRestriction.maxAge}-years`
        : "";

    const formattedQuestion = sanitizeQuestion(question);

    // Build the formattedRestrictions string based on available data
    let formattedRestrictions;
    if (cityState && gender && ageRange) {
      formattedRestrictions = `${cityState}/${gender}/${ageRange}`;
    } else if (cityState && gender) {
      formattedRestrictions = `${cityState}/${gender}`;
    } else if (cityState && ageRange) {
      formattedRestrictions = `${cityState}/${ageRange}`;
    } else if (gender && ageRange) {
      formattedRestrictions = `${gender}/${ageRange}`;
    } else if (cityState) {
      formattedRestrictions = cityState;
    } else if (gender) {
      formattedRestrictions = gender;
    } else if (ageRange) {
      formattedRestrictions = ageRange;
    } else {
      formattedRestrictions = "";
    }

    // If restrictions exist, append them to the question, otherwise just return the question
    if (formattedRestrictions) {
      return `${formattedQuestion}/${formattedRestrictions}`;
    } else {
      return formattedQuestion;
    }
  };

  const questionIdRestrictions = generateQuestionIdAndRestriction(
    data?.question,
    data?.locationRestriction,
    data?.genderRestriction,
    data?.ageRangeRestriction
  );

  const handleRedirect = () => {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      return;
    }
    dispatch(setPage(localPage));
    navigate(`/poll/${data?.category}/${questionIdRestrictions}`, {
      state: { id: data._id, page },
    });
  };

  const isFollowing = data.followers.some((item) => item?._id === user?._id);

  // const { comments, } = useQuery(FETCH_COMMENT, {
  //   variables: { pollId: pollId, questionId: questionId },
  //   pollInterval: 0, // how often do we want to refetch?
  // }

  return (
    <div className="col-span-12 sm:col-span-6 lg:col-span-4">
      <Spin spinning={loading}>
        <Drawer open={visible} onClose={() => setVisible(false)} width="100VW">
          <PollCardDrawer data={data} totalVote={data.totalVote} refetch={refetch} />
        </Drawer>
        <div
          className="bg-white rounded-md shadow-xl hover:shadow-2xl p-5 border relative"
          id="poll-card-parent"
          onClick={handleRedirect}
        >
          <div className="flex justify-between gap-x-2 items-baseline" id="poll-card-mgmt">
            <div className="text-2xl text-gray-700 mb-3">
              <div dangerouslySetInnerHTML={{ __html: data?.question }}></div>
            </div>
            <section className="flex items-center gap-x-4 md:gap-x-2">
              <Popover
                content={<ShareButtons url={window.location.href} title={data?.question} />} title="Share this poll" trigger={["click"]} placement="bottom" >
              <button
                className="text-[#7C83C8] cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                aria-label="Share this poll"
              >
                <i className="fas fa-share mr-2"></i>
              </button>
              </Popover>
              {isMine && (
                <Popconfirm
                  title="Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{ className: "danger", danger: true }}
                  onConfirm={onRemovePoll}
                >
                  <i className="far fa-trash-alt text-red-600 mr-2" onClick={(e) => e.stopPropagation()}></i>
                </Popconfirm>
              )}

              {!isMine && user?._id && (
                <span className="text-[#7C83C8] cursor-pointer" onClick={onAddOrRemoveFollower}>
                  <Tooltip placement="top" title={isFollowing ? "Unfollow" : "Follow"}>
                    {isFollowing ? <i className="fas fa-star"></i> : <i className="far fa-star"></i>}
                  </Tooltip>
                </span>
              )}
            </section>
          </div>

          <div className="flex flex-col">
            <section className="flex flex-wrap gap-x-4">
              <h5 className="text-base text-gray-700 mb-3">Votes: {totalVote}</h5>
              {data?.followers && (
                <h5 className="text-base text-gray-700 mb-3">
                  Following: {data.followers.length}
                </h5>
              )}
              <h5 className="text-base text-gray-700 mb-3">
                  Comments: {data?.commentsCount}
                </h5>
            </section>

            <div className="flex flex-wrap">
              {tags?.map(({ _id, text }) => (
                <Tag
                  key={_id}
                  className="mb-3"
                  onClick={(e) => {
                    e.stopPropagation();
                    filterByTag(text);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {text}
                </Tag>
              ))}
            </div>
            <div className="flex flex-wrap gap-y-2 mb-4">
              <RestrictionTag
                content={
                  data?.locationRestriction &&
                  (() => {
                    const { city, locationState } = data.locationRestriction;
                    return city && locationState && locationState !== "null" && locationState !== ""
                      ? `${city}, ${locationState}`
                      : city || locationState;
                  })()
                }
              />

              <RestrictionTag content={data?.genderRestriction?.gender && `${data.genderRestriction.gender}`} />

              <RestrictionTag
                content={
                  data?.ageRangeRestriction &&
                  data.ageRangeRestriction?.maxAge != null &&
                  data.ageRangeRestriction?.minAge != null &&
                  (() => {
                    const { minAge, maxAge } = data.ageRangeRestriction;
                    return minAge && maxAge
                      ? `${minAge}yrs. - ${maxAge}yrs.`
                      : minAge
                      ? `minimum age: ${minAge}`
                      : `maximum age: ${maxAge}`;
                  })()
                }
              />
            </div>
          </div>
          {options?.map((op) => (
            <PollOption
              {...op}
              key={op._id}
              isMine={isMine}
              isPollVoted={isPollVoted}
              totalVote={totalVote}
              onAddVote={onAddVote}
              refetch={refetch}
              pollId={data._id}
            />
          ))}
          {data?.suggestionable && !isMine && user?._id && data?.user?._id && (
            <AddSuggession refetch={refetch} pollId={data._id} />
          )}
        </div>
      </Spin>
    </div>
  );
};
