import {
  AppstoreOutlined,
  BulbOutlined,
  CoffeeOutlined,
  CompassOutlined,
  DollarCircleOutlined,
  HeartOutlined,
  PieChartOutlined,
  TeamOutlined,
  TrophyOutlined,
  VideoCameraOutlined,
  FilterOutlined,
  SearchOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Dropdown, Input, Menu, Modal } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import { setSearchText, setSearchCategory, setPage } from "../store/slices/auth.slice";
import FilterComponent from "../components/Settings/FilterComponent";

// Utility function for debouncing
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const HeaderSearchBar = () => {
  const dispatch = useAppDispatch();
  const { initialSearchText } = useSelector((state: any) => state?.auth || {});
  const { pathname } = useLocation();
  const debouncedSearchText = useDebounce(initialSearchText, 500);
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false); // State for full-screen search

  const filterType = pathname.includes("/poll/") ? "votes" : "polls";
  const dropdownProps = {
    overlay: <FilterComponent filterType={filterType} />,
    trigger: ["click", "hover"] as ("contextMenu" | "click" | "hover")[],
    mouseEnterDelay: 0.1, // Adjust the delay as needed
    mouseLeaveDelay: 1.0, // Adjust the delay as needed
  };

  useEffect(() => {
    dispatch(setSearchText({ searchText: debouncedSearchText }));
  }, [debouncedSearchText, dispatch]);

  const handleSearch = (e) => {
    dispatch(setSearchText({ searchText: e.target.value }));
    dispatch(setPage(1));
  };

  const searchBarShowingRoute = ["/", "/voted-polls", "/my-polls", "/followed-polls"];
  const showSearchBar = searchBarShowingRoute.some(
    (item) => pathname === item || pathname.includes("/categories/") || pathname.includes("/poll/")
  );
  if (!showSearchBar) return null;

  const handleCategoryClick = (key) => {
    dispatch(setSearchCategory({ searchCategory: key }));
    dispatch(setPage(1));
    navigate(`/categories/${key}`);
  };

  // Menu items with categories and icons
  const categories = (
    <Menu className="grid grid-cols-3 gap-2 bg-[#182341] text-white">
      {[
        { key: "politics", icon: <PieChartOutlined className="text-3xl mx-auto" alt="Politics" />, text: "Politics" },
        {
          key: "social",
          icon: <TeamOutlined className="text-3xl mx-auto" alt="Social Issues" />,
          text: "Social Issues",
        },
        {
          key: "economics",
          icon: <DollarCircleOutlined className="text-3xl mx-auto" alt="Economics" />,
          text: "Economics",
        },
        { key: "technology", icon: <BulbOutlined className="text-3xl mx-auto" alt="Technology" />, text: "Technology" },
        { key: "health", icon: <HeartOutlined className="text-3xl mx-auto" alt="Health" />, text: "Health" },
        { key: "media", icon: <VideoCameraOutlined className="text-3xl mx-auto" alt="Media" />, text: "Media" },
        { key: "sports", icon: <TrophyOutlined className="text-3xl mx-auto" alt="Sports" />, text: "Sports" },
        { key: "food", icon: <CoffeeOutlined className="text-3xl mx-auto" alt="Food" />, text: "Food" },
        { key: "travel", icon: <CompassOutlined className="text-3xl mx-auto" alt="Travel" />, text: "Travel" },
      ].map((item) => (
        <Menu.Item key={item.key} onClick={() => handleCategoryClick(item.key)} className="text-center py-3">
          {item.icon}
          <div>{item.text}</div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="flex flex-row items-center gap-3 w-full sm:w-1/3">
      {/* Mobile View */}
      {/* <div className="flex gap-x-3 sm:hidden">
        <SearchOutlined
          className="text-2xl text-white"
          onClick={() => setIsSearchOpen(true)} // Open the search modal
        />
        <Dropdown overlay={categories} trigger={["click", "hover"]}>
          <div className="font-semibold flex items-center text-white">
            <AppstoreOutlined className="flex text-2xl" />
          </div>
        </Dropdown>
        <Dropdown {...dropdownProps}>
          <div className="font-semibold flex items-center text-white">
            <FilterOutlined className="flex text-2xl" />
          </div>
        </Dropdown>
      </div> */}

      {/* Desktop View */}
      <div className="flex items-center gap-3 w-full">
        <Input
          className="px-2 py-2 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600"
          style={{ width: "100%" }}
          onChange={handleSearch}
          placeholder="Search by question title"
        />
        <Dropdown overlay={categories} trigger={["click", "hover"]}>
          <div className="font-semibold flex items-center text-white">
            <AppstoreOutlined className="flex text-2xl" />
          </div>
        </Dropdown>
        <Dropdown {...dropdownProps}>
          <div className="font-semibold flex items-center text-white">
            <FilterOutlined className="flex text-2xl" />
          </div>
        </Dropdown>
      </div>

      {/* Search Modal for Mobile */}
      {/* <Modal
        visible={isSearchOpen}
        footer={null}
        onCancel={() => setIsSearchOpen(false)} // Close the search modal
        closable={false}
        className="search-modal"
      >
        <div className="flex justify-between items-center p-3">
          <Input
            className="px-2 py-2 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 w-full"
            onChange={handleSearch}
            placeholder="Search by question title"
            suffix={<CloseOutlined onClick={() => setIsSearchOpen(false)} />}
          />
        </div>
      </Modal> */}
    </div>
  );
};

export default HeaderSearchBar;
