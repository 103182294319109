import { Link } from "react-router-dom";

export const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();
  return (
    <div className="text-base sm:text-lg">
      <section id="copyright" className="bg-[#35405f] text-white py-4 px-3">
        <div className="md:flex justify-center text-center relative">
          <div className="flex justify-center md:absolute left-0">
            <p className="text-sm text-gray-300 hover:underline me-3">
              <Link className="" to="/terms-and-conditions">
                Terms
              </Link>
            </p>

            <p className="text-sm text-gray-300 hover:underline me-3">
              <Link className="" to="/privacy-policy">
                Privacy
              </Link>
            </p>

            <p className="text-sm text-gray-300 hover:underline">
              <Link className="" to="/disclaimer">
                Disclaimer
              </Link>
            </p>
          </div>
          <p className="text-sm text-gray-300 md:mt-0 mt-3">
            Copyright ©{year} <span className="font-semibold">Agreeable LLC.</span> All rights reserved.
          </p>
          <div className="flex justify-center md:absolute right-1">
            <p className="text-sm text-gray-300 justify-right hover:underline me-3">
              <a className="" href="mailto:contact@pollsboard.com">
                Contact
              </a>
            </p>
            <p className="text-sm text-gray-300 justify-right hover:underline">
              <Link className="" to="/about-us">
                About
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
