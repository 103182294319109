import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import { resetPollsFilters, setPollsFilters, resetVotesFilters, setVotesFilters, setPage } from "../../store/slices/auth.slice";

// Define state options and age ranges
const stateOptions = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

const ageRanges = [
  { value: "18-24", label: "18-24" },
  { value: "25-34", label: "25-34" },
  { value: "35-44", label: "35-44" },
  { value: "45-54", label: "45-54" },
  { value: "55-64", label: "55-64" },
  { value: "65+", label: "65+" },
];

const genderOptions = [
  { value: "", label: "Select Gender" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

const cityOptions = [
  { value: "New York", label: "New York" },
  { value: "Los Angeles", label: "Los Angeles" },
  { value: "Chicago", label: "Chicago" },
  { value: "Houston", label: "Houston" },
  { value: "Phoenix", label: "Phoenix" },
  { value: "Philadelphia", label: "Philadelphia" },
  { value: "San Antonio", label: "San Antonio" },
  { value: "San Diego", label: "San Diego" },
  { value: "Dallas", label: "Dallas" },
  { value: "San Jose", label: "San Jose" },
  { value: "Austin", label: "Austin" },
  { value: "Jacksonville", label: "Jacksonville" },
  { value: "Fort Worth", label: "Fort Worth" },
  { value: "Columbus", label: "Columbus" },
  { value: "Charlotte", label: "Charlotte" },
  { value: "San Francisco", label: "San Francisco" },
  { value: "Indianapolis", label: "Indianapolis" },
  { value: "Seattle", label: "Seattle" },
  { value: "Denver", label: "Denver" },
  { value: "Washington", label: "Washington" },
];

const FilterComponent = ({ filterType }) => {
  const dispatch = useDispatch();
  const votesFilters = useSelector((state: any) => state?.auth.votesFilters);
  console.log("Votes Filters on mount:", votesFilters);
  const pollsFilters = useSelector((state: any) => state?.auth.pollsFilters);

  // useEffect(() => {
  //   console.log("Filters on mount:", votesFilters);
  // }, [votesFilters]);

  const handleChange = (name, selectedOption, filterType) => {
    let value;
    if (Array.isArray(selectedOption)) {
      value = selectedOption.map((option) => option.value);
    } else {
      value = selectedOption ? selectedOption.value : undefined;
    }

    if (name === "locationState" || name === "ageRange" || name === "city") {
      value = Array.isArray(value) ? value : [value];
    }

    if (filterType === "votes") {
      const newVotesFilters = { ...votesFilters, [name]: value };
      dispatch(setVotesFilters(newVotesFilters));
    } else if (filterType === "polls") {
      const newPollsFilters = { ...pollsFilters, [name]: value };
      dispatch(setPollsFilters(newPollsFilters));
      dispatch(setPage(1));
    }
  };

  const handleResetFilters = () => {
    if (filterType === "votes") {
      dispatch(resetVotesFilters(undefined));
    } else {
      dispatch(resetPollsFilters(undefined));
    }
    dispatch(setPage(1));
  };

  if (filterType === "votes") {
    return (
      <div className="ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical ant-dropdown-menu-light w-fit h-full bg-white border-r border-gray-200">
        <div className="flex gap-4 p-4">
          {/* Votes Filters */}
          <div className="flex flex-col mb-4">
            <h2 className="text-xl font-bold mb-2">Votes Filters</h2>
  
            <div className="flex flex-col mb-4">
              <label htmlFor="gender" className="mb-2 font-bold">
                Gender
              </label>
              <Select
                id="gender"
                name="gender"
                options={genderOptions}
                className="basic-single"
                classNamePrefix="select"
                value={
                  genderOptions.find((option) => option.value === votesFilters?.gender) || {
                    value: "",
                    label: "Select Gender",
                  }
                }
                onChange={(selectedOptions) => handleChange("gender", selectedOptions, "votes")}
              />
            </div>
  
            <div className="flex flex-col mb-4">
              <label htmlFor="city" className="mb-2 font-bold">
                City
              </label>
              <CreatableSelect
                id="city"
                name="city"
                isMulti
                options={cityOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                value={
                  votesFilters.city
                    ? votesFilters.city.map((city) => cityOptions.find((option) => option.value === city))
                    : []
                }
                onChange={(selectedOptions) => handleChange("city", selectedOptions, "votes")}
              />
            </div>
  
            <div className="flex flex-col mb-4">
              <label htmlFor="state" className="mb-2 font-bold">
                State
              </label>
              <Select
                id="state"
                name="state"
                isMulti
                options={stateOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                value={
                  votesFilters.locationState
                    ? votesFilters.locationState.map((state) => stateOptions.find((option) => option.value === state))
                    : []
                }
                onChange={(selectedOptions) => handleChange("locationState", selectedOptions, "votes")}
              />
            </div>
  
            <div className="flex flex-col mb-4">
              <label htmlFor="ageRange" className="mb-2 font-bold">
                Age Range
              </label>
              <Select
                id="ageRange"
                name="ageRange"
                isMulti
                options={ageRanges}
                className="basic-multi-select"
                classNamePrefix="select"
                value={
                  votesFilters.ageRange
                    ? votesFilters.ageRange.map((range) => ageRanges.find((option) => option.value === range))
                    : []
                }
                onChange={(selectedOptions) => handleChange("ageRange", selectedOptions, "votes")}
              />
            </div>
  
            <button
              onClick={handleResetFilters}
              className="mt-4 p-2 bg-[#182341] text-white font-bold rounded hover:bg-[#1C2833]"
            >
              Reset Votes Filters
            </button>
          </div>
        </div>
      </div>
    );
  } else if (filterType === "polls") {
    return (
      <div className="ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical ant-dropdown-menu-light w-fit h-full bg-white border-r border-gray-200">
        <div className="flex gap-4 p-4">
          {/* Polls Filters */}
          <div className="flex flex-col">
            <h2 className="text-xl font-bold mb-2">Polls Filters</h2>
  
            <div className="flex flex-col mb-4">
              <label htmlFor="gender" className="mb-2 font-bold">
                Gender
              </label>
              <Select
                id="gender"
                name="gender"
                options={genderOptions}
                className="basic-single"
                classNamePrefix="select"
                value={
                  genderOptions.find((option) => option.value === pollsFilters?.gender) || {
                    value: "",
                    label: "Select Gender",
                  }
                }
                onChange={(selectedOptions) => handleChange("gender", selectedOptions, "polls")}
              />
            </div>
  
            <div className="flex flex-col mb-4">
              <label htmlFor="city" className="mb-2 font-bold">
                City
              </label>
              <CreatableSelect
                id="city"
                name="city"
                isMulti
                options={cityOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                value={
                  pollsFilters.city
                    ? pollsFilters.city.map((city) => cityOptions.find((option) => option.value === city))
                    : []
                }
                onChange={(selectedOptions) => handleChange("city", selectedOptions, "polls")}
              />
            </div>
  
            <div className="flex flex-col mb-4">
              <label htmlFor="state" className="mb-2 font-bold">
                State
              </label>
              <Select
                id="state"
                name="state"
                isMulti
                options={stateOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                value={
                  pollsFilters.locationState
                    ? pollsFilters.locationState.map((state) => stateOptions.find((option) => option.value === state))
                    : []
                }
                onChange={(selectedOptions) => handleChange("locationState", selectedOptions, "polls")}
              />
            </div>
  
            <div className="flex flex-col mb-4">
              <label htmlFor="ageRange" className="mb-2 font-bold">
                Age Range
              </label>
              <Select
                id="ageRange"
                name="ageRange"
                isMulti
                options={ageRanges}
                className="basic-multi-select"
                classNamePrefix="select"
                value={
                  pollsFilters.ageRange
                    ? pollsFilters.ageRange.map((range) => ageRanges.find((option) => option.value === range))
                    : []
                }
                onChange={(selectedOptions) => handleChange("ageRange", selectedOptions, "polls")}
              />
            </div>
  
            <button
              onClick={handleResetFilters}
              className="mt-4 p-2 bg-[#182341] text-white font-bold rounded hover:bg-[#1C2833]"
            >
              Reset Polls Filters
            </button>
          </div>
        </div>
      </div>
    );
  }
  
};
export default FilterComponent;
