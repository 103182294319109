import { Button } from "../Shared";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { UPDATE_PASSWORD } from "../../graphql/models";
import { successNotify, warnNotify } from "../../util";
import { useMutation } from "@apollo/react-hooks";
import { onUserLogout } from "../../store/slices/auth.slice";
import { Modal, Spin } from "antd";

export const ChangePassword = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_new_password, setConfirmNewPassword] = useState("");

  // password change
  const [passwordChangeMutation, { loading }] = useMutation(UPDATE_PASSWORD);
  const handleUpdatePassword = async () => {
    try {
      const {
        data: { PasswordUpdate },
      } = await passwordChangeMutation({
        variables: {
          oldPassword: old_password,
          newPassword: new_password,
        },
      });
      if (PasswordUpdate.success) {
        successNotify(PasswordUpdate.message);
        dispatch(onUserLogout({}));
      } else {
        warnNotify(PasswordUpdate.message);
      }
    } catch (error) {}
  };

  const handleCancel = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setVisible(false);
  };

  return (
    <Spin spinning={loading}>
      <Modal open={visible} footer={false} onCancel={handleCancel}>
        <h2 className="text-center text-gray-600 text-3xl font-bold mb-5">Update Password</h2>
        <div className="mb-3">
          <div className="text-base text-gray-700 font-semibold mb-2">Old Password</div>
          <input
            className="border focus:border rounded-lg w-full px-4 py-2"
            type="password"
            placeholder="Old Password"
            name="old_password"
            value={old_password}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <div className="text-base text-gray-700 font-semibold mb-2">New Password</div>
          <input
            className="border focus:border w-full rounded-lg px-4 py-2"
            type="password"
            placeholder="New Password"
            name="new_password"
            value={new_password}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <div className="text-base text-gray-700 font-semibold mb-2">Confirm New Password</div>
          <input
            className="border focus:border w-full rounded-lg px-4 py-2"
            type="password"
            placeholder="Confirm Mew Password"
            name="confirm_new_password"
            value={confirm_new_password}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
        <div className="text-center ml-auto mt-10 mb-5">
          <Button
            text="Update"
            className="w-[250px] m-auto"
            loading={loading}
            onClick={handleUpdatePassword}
            disabled={new_password !== confirm_new_password || !new_password}
          />
        </div>
      </Modal>
    </Spin>
  );
};
