import { Outlet } from "react-router-dom";
import { Header } from "../container/Header";
import { Footer } from "../container/Footer";

export const Root = () => {
    return (
        <div className="flex flex-col">
            <div className="flex-col justify-start">
            <Header />
            </div>
            <div className="min-h-0 md:min-h-screen">
            <Outlet />
            </div>
            <div className="flex-col justify-end">
            <Footer />
            </div>
        </div>
    );
};