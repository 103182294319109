import { useQuery } from "@apollo/react-hooks";
import { Pagination } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { PollCard, PrivateContainer } from "../components";
import { FETCH_POLLS } from "../graphql/models";

export const MyPolls = () => {
  const [limit] = useState(9);
  const [page, setPage] = useState(1);

  const { user, reload, searchTag, searchText, pollsFilters } = useSelector((state: any) => state?.auth);

  const { data, loading, refetch } = useQuery(FETCH_POLLS, {
    variables: {
      userId: user._id,
      email: user.email,
      limit,
      offset: page - 1,
      reload,
      searchText,
      searchTag,
      pollsFilters: pollsFilters,
    },
    fetchPolicy: "cache-and-network",
  });

  console.log("User in MyPoll", user);
  console.log("User id in MyPoll", user._id);

  console.log("Data", data);
  console.log("My Polls", data?.FetchPoll?.result || []);

  const polls = data?.FetchPoll?.result || [];
  const total = data?.FetchPoll?.count || 0;

  // page handler
  const onChangePagination = (p) => {
    setPage(p);
  };

  return (
    <PrivateContainer pageTitle="My polls" loading={loading}>
      {!polls.length && !loading && <p className="text-center text-lg text-black w-full">There is no poll</p>}
      <div className="grid grid-cols-12 gap-5 min-h-[50vh]">
        {polls?.map((poll: any) => (
          <PollCard key={poll?._id} data={poll} totalVote={poll.totalVote} refetch={refetch} />
        ))}
      </div>
      {!!polls.length && (
        <Pagination
          className="flex justify-center mt-10"
          total={total}
          pageSize={limit}
          current={page}
          showSizeChanger={false}
          onChange={onChangePagination}
        />
      )}
    </PrivateContainer>
  );
};
